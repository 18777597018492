import config from 'core/config';

export const authorizationModule = {
    namespaced: true,
    state: {
        userId: config?.userData?.userId,
    },
    getters: {},
    mutations: {
        setUserId(state, userId) {
            state.userId = userId;
        },
    },
    actions: {
        setUserId({commit}, userId) {
            commit('setNNewMessages', userId);
        },
    },
};