export const STATUS_NEW                = 0;
export const STATUS_ACCEPTED           = 2;
export const STATUS_COMPLETED          = 3;
export const STATUS_CANCELED_BY_CLIENT = 5;
export const STATUS_COMPLETED_BY_ADMIN = 6;

export const ALLOW_PROCESS_STATUSES = [
    STATUS_NEW,
    STATUS_ACCEPTED,
    STATUS_CANCELED_BY_CLIENT,
];

export const COMPLETED_STATUSES = [
    STATUS_COMPLETED,
    STATUS_COMPLETED_BY_ADMIN,
];