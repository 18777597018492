class Config {
    constructor(config = null) {
        if (this.constructor.instance) {
            return this.constructor.instance;
        }
        this.constructor.instance = this;
        this.init(config || typeof window !== 'undefined' && window.CONFIG);
    }
    init(config = {}) {
        if (typeof config !== 'object') {
            return;
        }
        Object.keys(config).forEach(
            param => {
                this[param] = config[param];
            }
        );
    }
}
const config = new Config();
export default config;
