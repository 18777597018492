import {STATUS_NEW} from 'common_constants/alarm_statuses';

export const alarms_module = {
    namespaced: true,
    state:      {
        newAlarms:   [],
        initialized: false,
    },
    getters:    {
        nNewAlarms: state => state.newAlarms.filter(alarm => alarm.confirm === STATUS_NEW).length,
        nNewTestAlarms: state => state.newAlarms.filter(alarm => alarm.test).length,
    },
    mutations:  {
        setNewAlarms(state, alarms) {
            if (!alarms.length) {
                return;
            }
            state.newAlarms = alarms;
            state.initialized = true;
        },
        deleteNewAlarm(state, needleAlarmId) {
            const findCallBack      = alarm => alarm.alarm_id === needleAlarmId;
            const findNewAlarmIndex = state.newAlarms.findIndex(findCallBack);
            if (findNewAlarmIndex !== -1) {
                state.newAlarms.splice(findNewAlarmIndex, 1);
            }
        },
    },
    actions:    {
        setNewAlarms({commit}, alarms) {
            commit('setNewAlarms', alarms);
        },
        deleteNewAlarm({commit}, needleAlarmId) {
            commit('deleteNewAlarm', needleAlarmId);
        },
    },
};
