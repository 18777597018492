import config from 'core/config';

export const messagesModule = {
    namespaced: true,
    state:      {
        nNewMessages: config.nNewMessages,
        openedChatId: null,
    },
    getters:    {},
    mutations:  {
        setOpenedChatId(state, openedChatId) {
            state.openedChatId = openedChatId;
        },
        setNNewMessages(state, nNewMessages) {
            state.nNewMessages = nNewMessages;
        },
        increaseNNewMessages(state) {
            state.nNewMessages ++;
        },
    },
    actions:    {
        setOpenedChatId({commit}, openedChatId) {
            commit('setOpenedChatId', openedChatId);
        },
        resetOpenedChatId({commit}) {
            commit('setOpenedChatId', null);
        },
        setNNewMessages({commit}, nNewMessages) {
            commit('setNNewMessages', nNewMessages);
        },
        increaseNNewMessages({commit}) {
            commit('increaseNNewMessages');
        },
        decreaseNNewMessages({commit, state}, n) {
            commit('setNNewMessages', state.nNewMessages - n);
        },
    },
};