export const ConstantsPlugin = {
    install(Vue) {
        if (Vue.prototype.hasOwnProperty('$consts')) {
            return;
        }
        Object.defineProperty(Vue.prototype, '$consts', {
            configurable: false,
            enumerable: false,
            get() {
                return this.$options.constants;
            },
        });
        Vue.config.optionMergeStrategies.constants = function (parent_val, child_val) {
            if (!parent_val) {
                return child_val;
            }
            if (!child_val) {
                return parent_val;
            }
            return {...parent_val, ...child_val};
        };
    },
};

export const VueConstants = {
    install(Vue) {
        if (Vue.prototype.hasOwnProperty('$consts')) {
            return;
        }
        Object.defineProperty(Vue.prototype, '$consts', {
            configurable: false,
            enumerable: false,
            get() {
                return this.$options.constants;
            },
        });
        Vue.config.optionMergeStrategies.constants = function (parent_val, child_val) {
            if (!parent_val) {
                return child_val;
            }
            if (!child_val) {
                return parent_val;
            }
            return {...parent_val, ...child_val};
        };
    },
};
