import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import {VueConstants} from 'common_plugins/constants';
import VueAxios  from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import VModal from 'vue-js-modal';
import VueCookie from 'vue-cookie';
import VueScrollStop from 'vue-scroll-stop';
import Vuelidate from 'vuelidate';
import Notifications from 'vue-notification';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import axios from 'axios';
import "core-js/stable";
import "regenerator-runtime/runtime";

import 'sweetalert2/dist/sweetalert2.min.css';
import {alarms_module} from 'common_store_modules/alarms';
import {messagesModule} from 'common_store_modules/messages';
import {authorizationModule} from 'common_store_modules/authorization';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.use(VueScrollStop);
Vue.use(PerfectScrollbar);
Vue.use(VModal, { dynamic : true, injectModalsContainer: true});
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(VueCookie);
Vue.use(VueConstants);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(Notifications);
Vue.use(Vuelidate);

window.Vue          = Vue;
window.Vuex         = Vuex;
window.VueRouter    = VueRouter;

const store_global = new Vuex.Store({
    state: () =>  {
        return {
            global_status: 10,
        };
    },
    getters: {},
    mutations:  {
        statusGlobalPlus: state => {
            state.global_status++;
        },
    },
    actions: {
        statusGlobalPlus: (context) => {
            context.commit('statusGlobalPlus');
        },
    },
    modules: {
        alarms: alarms_module,
        messages: messagesModule,
        authorization: authorizationModule,
    },
});

window.store_global = store_global;